import {Conference} from "./types/Conference";
import {
    ARI,
    ATL,
    BAL,
    BUF, CAR,
    CHI,
    CIN,
    CLE,
    DAL, DEN,
    DET,
    GB, HOU,
    Icon, IND, JAX, KC, LAC, LAR,
    LV,
    MIA,
    MIN,
    NE, NO,
    NYG,
    NYJ,
    PHI,
    PIT, SEA, SF, TB,
    WAS
} from "react-nfl-logos";
import Ten from "./vendor/logos/ten";

export const NFLDivisionMapping: Record<string, string> = {
    // AFC East
    BUF: "AFC_EAST",
    MIA: "AFC_EAST",
    NE: "AFC_EAST",
    NYJ: "AFC_EAST",

    // AFC North
    BAL: "AFC_NORTH",
    CIN: "AFC_NORTH",
    CLE: "AFC_NORTH",
    PIT: "AFC_NORTH",

    // AFC South
    HOU: "AFC_SOUTH",
    IND: "AFC_SOUTH",
    JAX: "AFC_SOUTH",
    TEN: "AFC_SOUTH",

    // AFC West
    DEN: "AFC_WEST",
    KC: "AFC_WEST",
    LAC: "AFC_WEST",
    LV: "AFC_WEST",

    // NFC East
    DAL: "NFC_EAST",
    NYG: "NFC_EAST",
    PHI: "NFC_EAST",
    WAS: "NFC_EAST",

    // NFC North
    CHI: "NFC_NORTH",
    DET: "NFC_NORTH",
    GB: "NFC_NORTH",
    MIN: "NFC_NORTH",

    // NFC South
    ATL: "NFC_SOUTH",
    CAR: "NFC_SOUTH",
    NO: "NFC_SOUTH",
    TB: "NFC_SOUTH",

    // NFC West
    ARI: "NFC_WEST",
    LA: "NFC_WEST",
    SF: "NFC_WEST",
    SEA: "NFC_WEST"
};

export const NFLDivisionInverseMapping: Record<string, string[]> = {
    // AFC East
    AFC_EAST: ["BUF", "MIA", "NE", "NYJ"],

    // AFC North
    AFC_NORTH: ["BAL", "CIN", "CLE", "PIT"],

    // AFC South
    AFC_SOUTH: ["HOU", "IND", "JAX", "TEN"],

    // AFC West
    AFC_WEST: ["DEN", "KC", "LAC", "LV"],

    // NFC East
    NFC_EAST: ["DAL", "NYG", "PHI", "WAS"],

    // NFC North
    NFC_NORTH: ["CHI", "DET", "GB", "MIN"],

    // NFC South
    NFC_SOUTH: ["ATL", "CAR", "NO", "TB"],

    // NFC West
    NFC_WEST: ["ARI", "LA", "SF", "SEA"]
};

export const NFLConferenceMapping: Record<string, Record<string, Array<string>>> = {
    AFC: {
        AFC_EAST: ["BUF", "MIA", "NE", "NYJ"],
        AFC_NORTH: ["BAL", "CIN", "CLE", "PIT"],
        AFC_SOUTH: ["HOU", "IND", "JAX", "TEN"],
        AFC_WEST: ["DEN", "KC", "LAC", "LV"],
    },
    NFC: {
        NFC_EAST: ["DAL", "NYG", "PHI", "WAS"],
        NFC_NORTH: ["CHI", "DET", "GB", "MIN"],
        NFC_SOUTH: ["ATL", "CAR", "NO", "TB"],
        NFC_WEST: ["ARI", "LA", "SF", "SEA"],
    }
};

export const Divisions = [
    "AFC_EAST",
    "AFC_NORTH",
    "AFC_SOUTH",
    "AFC_WEST",
    "NFC_EAST",
    "NFC_NORTH",
    "NFC_SOUTH",
    "NFC_WEST",
]

export const Confences: Array<Conference> = [
    Conference.AFC,
    Conference.NFC
]


export const NFLLogos: Record<string, Icon> = {
    // AFC East
    BUF: BUF,
    MIA: MIA,
    NE: NE,
    NYJ: NYJ,

    // AFC North
    BAL: BAL,
    CIN: CIN,
    CLE: CLE,
    PIT: PIT,

    // AFC South
    HOU: HOU,
    IND: IND,
    JAX: JAX,
    TEN: Ten,

    // AFC West
    DEN: DEN,
    KC: KC,
    LAC: LAC,
    LV: LV,

    // NFC East
    DAL: DAL,
    NYG: NYG,
    PHI: PHI,
    WAS: WAS,

    // NFC North
    CHI: CHI,
    DET: DET,
    GB: GB,
    MIN: MIN,

    // NFC South
    ATL: ATL,
    CAR: CAR,
    NO: NO,
    TB: TB,

    // NFC West
    ARI: ARI,
    LA: LAR,
    SF: SF,
    SEA: SEA
};