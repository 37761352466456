import React, {useState, useEffect} from 'react';
import {CalculatePlayoffPercentages, MonteCarloPlayoffRuns} from "./lib/Predictions";
import {ScheduleItem} from "./types/ScheduleItem";
import {NFLDivisionMapping, NFLLogos, NFLConferenceMapping} from "./Constants";
import {Icon} from "react-nfl-logos";

interface PlayoffOddsProps {
    schedule: Array<ScheduleItem>;
}

type PlayoffPercentages = {
    byesPercentages: Record<string, number>;
    divisionWinnersPercentages: Record<string, number>;
    wildcardsPercentages: Record<string, number>;
    nonPlayoffAppearancesPercentages: Record<string, number>;
};

type TeamRecords = {
    [key: string]: {
        wins: number;
        losses: number;
        confWins: number;
        confLosses: number;
        divWins: number;
        divLosses: number;
    };
};

export const PlayoffOdds: React.FC<PlayoffOddsProps> = ({schedule}) => {
    const [currentSchedule, setCurrentSchedule] = useState<Array<ScheduleItem>>(schedule);
    const [calculatedPercentages, setCalculatedPercentages] = useState<PlayoffPercentages | null>(null);
    const [teamRecords, setTeamRecords] = useState<TeamRecords>({});

    useEffect(() => {
        if (schedule && schedule.length > 0) {
            setCurrentSchedule(schedule);
        }
    }, [schedule]);

    useEffect(() => {
        const results = MonteCarloPlayoffRuns(currentSchedule, 1500);
        setCalculatedPercentages(CalculatePlayoffPercentages(results, 1500));
        setTeamRecords(calculateTeamRecords(currentSchedule));
        console.log("crunching the numbers")
    }, [currentSchedule]);

    const calculateTeamRecords = (schedule: Array<ScheduleItem>) => {
        const records: TeamRecords = {};
        Object.keys(NFLDivisionMapping).forEach(team => {
            records[team] = {wins: 0, losses: 0, confWins: 0, confLosses: 0, divWins: 0, divLosses: 0};
        });

        schedule.forEach(game => {
            if (game.away_score !== 0 || game.home_score !== 0) {
                const homeDivision = NFLDivisionMapping[game.home_team];
                const awayDivision = NFLDivisionMapping[game.away_team];
                const isDivisionGame = homeDivision === awayDivision;
                const isConferenceGame = homeDivision.split('_')[0] === awayDivision.split('_')[0];

                const isHomeWin = game.home_score > game.away_score;
                const winner = isHomeWin ? game.home_team : game.away_team;
                const loser = isHomeWin ? game.away_team : game.home_team;

                records[winner].wins++;
                records[loser].losses++;

                if (isConferenceGame) {
                    records[winner].confWins++;
                    records[loser].confLosses++;
                }
                if (isDivisionGame) {
                    records[winner].divWins++;
                    records[loser].divLosses++;
                }
            }
        });
        return records;
    };

    const handleGameResult = (gameId: string, homeWin: boolean) => {
        const updatedSchedule = currentSchedule.map(game => {
            if (game.game_id === gameId) {
                let homeScore = homeWin ? 1 : -1
                let awayScore = homeWin ? -1 : 1
                let isUnset = game.home_score === homeScore && game.away_score === awayScore;
                return {
                    ...game,
                    home_score: isUnset ? 0 : homeScore,
                    away_score: isUnset ? 0 : awayScore,
                    has_score_override: !isUnset
                };
            }
            return game;
        });

        setCurrentSchedule(updatedSchedule);
    };

    const futureWeeks = Array.from(
        new Set(
            schedule
                .filter(game => game.home_score === 0 && game.away_score === 0)
                .map(game => game.week)
        )
    ).sort((a, b) => a - b);

    if (!calculatedPercentages || !schedule || schedule.length === 0) {
        return <div></div>;
    }

    console.log("hello")

    return (
        <div className="font-inter p-5 text-xxs flex justify-center flex-col space-y-6 bg-gray-100">
            {Object.keys(NFLConferenceMapping).map(conference => (
                <table
                    style={{width: "1000px"}}
                    className="m-auto py-5 w-full bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <tbody>
                    <React.Fragment key={conference}>
                        <tr className="text-white font-semibold text-left tracking-wider"
                            style={{backgroundColor: `${conference === 'AFC' ? "#c61f32" : "#0c3e63"}`}}>
                            <th colSpan={4}
                                className="py-2 text-center text-sm font-bold">{conference}</th>
                            <th className="py-2 text-center text-sm font-bold" colSpan={futureWeeks.length}>Weeks</th>
                            <th className="py-2 text-center text-sm font-bold" colSpan={4}>Probabilities</th>
                        </tr>
                        <tr className="text-white font-semibold tracking-wide"
                            style={{backgroundColor: `${conference === 'AFC' ? "#c61f32" : "#0c3e63"}`}}>
                            <th className="px-4 py-3 border-b text-center">Team</th>
                            <th className="px-1 py-3 border-b text-center">Overall</th>
                            <th className="px-1 py-3 border-b text-center">Conf</th>
                            <th className="px-1 py-3 border-b text-center">Div</th>
                            {futureWeeks.map((week, index) => (
                                <th key={week}
                                    className={`px-3 py-3 border-b text-center tracking-wide`}>{week}</th>
                            ))}
                            <th className="px-2 py-3 border-b text-center">Bye</th>
                            <th className="px-2 py-3 border-b text-center">Division</th>
                            <th className="px-2 py-3 border-b text-center">Wildcard</th>
                            <th className="px-2 py-3 border-b text-center">Any</th>
                        </tr>
                        {Object.keys(NFLConferenceMapping[conference]).flatMap(division => [
                            <tr key={division} className="text-left">
                                <th colSpan={8 + futureWeeks.length}
                                    className="py-3 px-4 text-xxs font-semibold">{division.replace('_', ' ')}</th>
                            </tr>,
                            ...NFLConferenceMapping[conference][division].map(team => {
                                const TeamLogo: Icon = NFLLogos[team];
                                const record = teamRecords[team];
                                return (
                                    <tr key={team} className="h-10">
                                        <td className="pl-3 border-t border-b border-gray-200">
                                            <TeamLogo size={32}/>
                                        </td>
                                        <td className="px-1 border-t border-b border-gray-200 text-center font-medium text-gray-700">{record?.wins}-{record?.losses}</td>
                                        <td className="px-1 border-t border-b border-gray-200 text-center font-medium text-gray-700">{record?.confWins}-{record?.confLosses}</td>
                                        <td className="px-1 border-t border-b  border-gray-200 text-center font-medium text-gray-700">{record?.divWins}-{record?.divLosses}</td>
                                        {futureWeeks.map(week => {
                                            const game = currentSchedule.find(
                                                game =>
                                                    game.week === week &&
                                                    (game.home_team === team || game.away_team === team)
                                            );
                                            if (game) {
                                                const teamIsHome = game.home_team === team;
                                                const homeWin = game.home_score > game.away_score;
                                                const awayWin = game.away_score > game.home_score;
                                                const isUnplayed = game.away_score === 0 && game.home_score === 0;
                                                const isGreen = (teamIsHome && homeWin) || (!teamIsHome && awayWin);

                                                let moneylineStr: string = "TBD";
                                                if (game.home_moneyline) {
                                                    let ml = game.home_moneyline;
                                                    if (!teamIsHome) {
                                                        ml = -ml;
                                                    }
                                                    moneylineStr = ml > 0 ? `+${ml}` : `${ml}`;
                                                }
                                                return <td key={`${team}-week-${week}`}
                                                           className="px-1 border-t border-b border-gray-200 text-center font-medium text-gray-600">
                                                    <div className="inline-flex rounded-md shadow-sm" role="group">
                                                        <button type="button"
                                                                onClick={() => handleGameResult(game.game_id, teamIsHome)}
                                                                className={` px-1 text-xxxs border border-gray-200 rounded-l-md hover:bg-green-200`}
                                                        >
                                                            W
                                                        </button>
                                                        <span
                                                            className={`border-t border-b border-gray-200 p-0.5 w-9 flex flex-col items-center justify-center ${isUnplayed ? 'bg-white' : (isGreen ? 'bg-green-200' : 'bg-red-200')}`}>
                                                                <div className="leading-3 text-xxxs text-gray-700 tracking-wide font-semibold">
                                                                    {teamIsHome ? game.away_team : `@${game.home_team}`}
                                                                </div>
                                                                <div className=" leading-3 text-xxxs text-gray-600">
                                                                    {moneylineStr}
                                                                </div>
                                                            </span>
                                                        <button type="button"
                                                                onClick={() => handleGameResult(game.game_id, !teamIsHome)}
                                                                className={` px-1 text-xxxs  border border-gray-200 rounded-r-md hover:bg-red-200`}
                                                        >
                                                            L
                                                        </button>
                                                    </div>

                                                </td>
                                            } else {
                                                return <td key={`${team}-week-${week}`}
                                                           className="px-1 border-t border-b border-gray-200 text-left font-medium text-gray-600">
                                                    <div className="text-center">Bye</div>
                                                </td>
                                            }
                                        })}
                                        <td className="w-16 px-1 border-t border-b text-center border-gray-200 ">{calculatedPercentages.byesPercentages[team]?.toFixed(2) ?? Number(0).toFixed(2)}%</td>
                                        <td className="w-16 px-1 border-t border-b text-center border-gray-200 ">{calculatedPercentages.divisionWinnersPercentages[team]?.toFixed(2) ?? Number(0).toFixed(2)}%</td>
                                        <td className="w-16 px-1 border-t border-b text-center border-gray-200 ">{calculatedPercentages.wildcardsPercentages[team]?.toFixed(2) ?? Number(0).toFixed(2)}%</td>
                                        <td className="w-16 px-1 border-t border-b text-center border-gray-200 ">{(100 - calculatedPercentages.nonPlayoffAppearancesPercentages[team])?.toFixed(2) ?? Number(0).toFixed(2)}%</td>
                                    </tr>
                                );
                            })
                        ])}
                    </React.Fragment>
                    </tbody>
                </table>
            ))}
        </div>
    );
};

export default PlayoffOdds;
