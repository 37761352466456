import {ScheduleItem} from "../types/ScheduleItem";
import {Confences, Divisions, NFLDivisionMapping} from "../Constants";
import {Conference} from "../types/Conference";

export interface Standing {
    wins: number;
    losses: number;
    div_wins: number;
    conf_wins: number;
    wins_map: Record<string, number>
}

export interface ConferenceSeeding {
    bye: string
    divisions: Array<string>,
    wildcards: Array<string>
}

export function MonteCarloRun(scheduleData: Array<ScheduleItem>): Record<string, Standing> {
    // Initialize standings
    let standings: Record<string, Standing> = {};
    for (const team of Object.keys(NFLDivisionMapping)) {
        standings[team] = { wins: 0, losses:0, div_wins: 0, conf_wins: 0, wins_map: {} };
    }

    for (const game of scheduleData) {
        const homeDivision = NFLDivisionMapping[game.home_team];
        const awayDivision = NFLDivisionMapping[game.away_team];
        const isDivisionGame = homeDivision === awayDivision;
        const isConferenceGame = homeDivision.split('_')[0] === awayDivision.split('_')[0];

        let winner = '';
        let loser = '';

        // Process games
        if ((game.home_score !== 0 || game.away_score !== 0) && (game.home_score !== null || game.away_score !== null)) {
            // Past games
            if (game.home_score > game.away_score) {
                winner = game.home_team;
                loser = game.away_team;
            } else {
                winner = game.away_team;
                loser = game.home_team;
            }
        } else {
            let winProb = 0.5;

            if (game.home_moneyline) {
                winProb = calculateImpliedProbability(game.home_moneyline)
            }

            if (Math.random() < winProb) {
                winner = game.home_team;
                loser = game.away_team;
            } else {
                winner = game.away_team;
                loser = game.home_team;
            }
        }

        // Update standings for the winner
        standings[winner].wins++;
        standings[loser].losses++;
        if (isDivisionGame) standings[winner].div_wins++;
        if (isConferenceGame) standings[winner].conf_wins++;

        // Update wins_map
        standings[winner].wins_map[loser] = (standings[winner].wins_map[loser] || 0) + 1;
    }

    return standings;
}


export function GetDivisionWinners(standings: Record<string, Standing>): Record<string, Standing> {
    const divisionWinners: Record<string, Standing> = {};

    Divisions.forEach(division => {
        const teamsInDivision = Object.keys(standings).filter(team => NFLDivisionMapping[team] === division);

        // Sort the teams in the division
        teamsInDivision.sort((a, b) => {
            const aStanding = standings[a];
            const bStanding = standings[b];

            // Sort by total wins
            if (aStanding.wins !== bStanding.wins) {
                return bStanding.wins - aStanding.wins;
            }

            // Sort by head-to-head wins
            const headToHeadAWins = aStanding.wins_map[b] || 0;
            const headToHeadBWins = bStanding.wins_map[a] || 0;
            if (headToHeadAWins !== headToHeadBWins) {
                return headToHeadBWins - headToHeadAWins;
            }

            const divisionComparison = bStanding.div_wins - aStanding.div_wins;
            if (divisionComparison !== 0) {
                return divisionComparison;
            }

            const commonOpponentsAWins = calculateCommonOpponentsWins(a, b, standings);
            const commonOpponentsBWins = calculateCommonOpponentsWins(b, a, standings);

            const commonOpponentsComparison = commonOpponentsBWins - commonOpponentsAWins;
            if (commonOpponentsComparison !== 0) {
                return commonOpponentsComparison;
            }

            // Compare by strength of victory
            const strengthOfVictoryA = CalculateStrengthOfVictory(a, standings);
            const strengthOfVictoryB = CalculateStrengthOfVictory(b, standings);
            return strengthOfVictoryB - strengthOfVictoryA;
        });

        // Take the top team as the division winner
        if (teamsInDivision.length > 0) {
            const divisionWinner = teamsInDivision[0];
            divisionWinners[divisionWinner] = standings[divisionWinner];
        }
    });

    return divisionWinners;
}

export function RankConference(standings: Record<string, Standing>, conf: Conference): Array<string> {
    // Determine the conference prefix (e.g., 'AFC' or 'NFC')
    const confPrefix = Conference[conf];

    // Filter teams by conference
    const confTeams = Object.keys(standings).filter(team => NFLDivisionMapping[team].startsWith(confPrefix));

    // Sort the teams
    confTeams.sort((a, b) => {
        const aStanding = standings[a];
        const bStanding = standings[b];

        // Compare by total wins
        if (aStanding.wins !== bStanding.wins) {
            return bStanding.wins - aStanding.wins;
        }

        // Compare by head-to-head performance, if applicable
        const headToHeadAWins = aStanding.wins_map[b] || 0;
        const headToHeadBWins = bStanding.wins_map[a] || 0;
        if (headToHeadAWins !== headToHeadBWins) {
            return headToHeadBWins - headToHeadAWins;
        }

        const conferenceComparison = bStanding.conf_wins - aStanding.conf_wins;
        if (conferenceComparison !== 0) {
            return conferenceComparison;
        }

        // Compare by win total of common opponents
        const commonOpponentsAWins = calculateCommonOpponentsWins(a, b, standings);
        const commonOpponentsBWins = calculateCommonOpponentsWins(b, a, standings);

        const commonOpponentsComparison = commonOpponentsBWins - commonOpponentsAWins;
        if (commonOpponentsComparison !== 0) {
            return commonOpponentsComparison;
        }

        // Compare by strength of victory
        const strengthOfVictoryA = CalculateStrengthOfVictory(a, standings);
        const strengthOfVictoryB = CalculateStrengthOfVictory(b, standings);
        return strengthOfVictoryB - strengthOfVictoryA;

    });

    return confTeams;
}

function calculateCommonOpponentsWins(teamA: string, teamB: string, standings: Record<string, Standing>): number {
    const teamACommonOpponents = Object.keys(standings[teamA].wins_map);
    const teamBWinsMap = standings[teamB].wins_map;
    let commonWins = 0;

    teamACommonOpponents.forEach(opponent => {
        if (opponent in teamBWinsMap) {
            commonWins += teamBWinsMap[opponent];
        }
    });

    return commonWins;
}

export function PlayoffSeeding(standings: Record<string, Standing>, conf: Conference): ConferenceSeeding {
    const divisionWinners = GetDivisionWinners(standings)
    const rankedDivisionWinners = RankConference(divisionWinners, conf)

    const remainingTeams = Object.keys(standings)
        .filter(team => !divisionWinners.hasOwnProperty(team))
        .reduce((acc, currentTeam) => {
            acc[currentTeam] = standings[currentTeam];
            return acc;
        }, {} as Record<string, Standing>);

    const rankedRemaining = RankConference(remainingTeams,conf)

    return {bye: rankedDivisionWinners[0], divisions: rankedDivisionWinners.slice(0,4), wildcards: rankedRemaining.slice(0,3)}
}

export function MonteCarloPlayoffRuns(scheduleData: Array<ScheduleItem>, runs: number): {
    byes: Record<string, number>,
    divisionWinners: Record<string, number>,
    wildcards: Record<string, number>,
    nonPlayoffAppearances: Record<string, number>
} {
    const byes: Record<string, number> = {};
    const divisionWinners: Record<string, number> = {};
    const wildcards: Record<string, number> = {};
    const nonPlayoffAppearances: Record<string, number> = {};

    // Initialize non-playoff appearances count
    for (const team of Object.keys(NFLDivisionMapping)) {
        nonPlayoffAppearances[team] = 0;
    }

    for (let i = 0; i < runs; i++) {
        const standings = MonteCarloRun(scheduleData);


        Confences.forEach((conf) => {

            const seeding = PlayoffSeeding(standings, conf);

            // Update counts for byes, division winners, and wildcards
            byes[seeding.bye] = (byes[seeding.bye] || 0) + 1;

            seeding.divisions.forEach(team => {
                divisionWinners[team] = (divisionWinners[team] || 0) + 1;
            });

            seeding.wildcards.forEach(team => {
                wildcards[team] = (wildcards[team] || 0) + 1;
            });

            // Update non-playoff appearances
            for (const team of Object.keys(NFLDivisionMapping)) {
                if (!NFLDivisionMapping[team].startsWith(Conference[conf])) {
                    continue;
                }
                if (team !== seeding.bye && !seeding.divisions.includes(team) && !seeding.wildcards.includes(team)) {
                    nonPlayoffAppearances[team]++;
                }
            }


        })

    }

    return { byes, divisionWinners, wildcards, nonPlayoffAppearances };
}

export function CalculatePlayoffPercentages(playoffResults: {
    byes: Record<string, number>,
    divisionWinners: Record<string, number>,
    wildcards: Record<string, number>,
    nonPlayoffAppearances: Record<string, number>
}, totalRuns: number): {
    byesPercentages: Record<string, number>,
    divisionWinnersPercentages: Record<string, number>,
    wildcardsPercentages: Record<string, number>,
    nonPlayoffAppearancesPercentages: Record<string, number>
} {
    const calculatePercentage = (category: Record<string, number>) =>
        Object.fromEntries(
            Object.entries(category).map(([team, count]) => [team, (count / totalRuns) * 100])
        );

    return {
        byesPercentages: calculatePercentage(playoffResults.byes),
        divisionWinnersPercentages: calculatePercentage(playoffResults.divisionWinners),
        wildcardsPercentages: calculatePercentage(playoffResults.wildcards),
        nonPlayoffAppearancesPercentages: calculatePercentage(playoffResults.nonPlayoffAppearances)
    };
}

function calculateImpliedProbability(moneyline: number): number {
    if (moneyline > 0) {
        // For positive moneyline odds
        return 100 / (moneyline + 100);
    } else {
        // For negative moneyline odds
        return Math.abs(moneyline) / (Math.abs(moneyline) + 100);
    }
}

export function CalculateStrengthOfVictory(team: string, standings: Record<string, Standing>): number {
    let totalWins = 0.0;
    let totalGames = 0.0;

    Object.keys(standings[team].wins_map).forEach(opponent => {
        totalWins += standings[opponent]?.wins;
        totalGames += standings[opponent]?.wins + standings[opponent]?.losses;
    });

    // Return the win percentage of the opponents they have beaten
    return totalGames > 0 ? totalWins / totalGames : 0;
}